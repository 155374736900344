<template>
	<v-container>
		<v-row class="text-center">
			<v-col cols="12">
				<h2>
					Eigen | Wijs | Ontwikkelen
				</h2>
				<h2>met Humanwise</h2>
			</v-col>

			<v-col cols="12">
				<v-divider></v-divider>
			</v-col>

			<v-col cols="12" class="text-center">
				<p class="font-weight-regular">
					Welkom op jouw Humanwise Eigen | Wijs | Ontwikkelen omgeving.<br>
					In deze app zie je de uitkomsten van jouw analyses.<br>
					Word je geholpen bij het opstellen van jouw individueel ontwikkel plan en kun je collega's vragen om feedback.<br>
				</p>
			</v-col>

			<v-col cols="12" class="text-center">
				<p class="font-weight-regular">
					Jouw persoonlijke informatie en uitkomsten van de analyses (persoonlijk en eventueel van jouw team) vind je onder de knop <span
					class="color-accent" style="font-weight: bold">Eigen</span>.
				</p>
			</v-col>

			<v-col cols="12" class="text-center">
				<p class="font-weight-regular">
					Het maken van jouw persoonlijk ontwikkel plan en het team ontwikkel plan vind je onder de knop <span class="color-accent" style="font-weight: bold">Wijs</span>.
				</p>
			</v-col>

			<v-col cols="12" class="text-center">
				<p class="font-weight-regular">
					Het vragen van feedback vind je onder de knop <span class="color-accent" style="font-weight: bold">Ontwikkelen</span>.
				</p>
			</v-col>

			<v-col cols="12" v-if="user.teams.length > 1">
				<v-divider></v-divider>
			</v-col>

			<v-col cols="12" md="6" offset-md="3" class="text-center" v-if="user.teams.length > 1">
				<h3>Teams</h3>
				<p class="font-weight-regular">
					Je bent lid van meerdere teams. Hieronder kun je het active team wijzigen.
				</p>
				<v-select
					:items="user.teams"
					:label="user.selected_team.name"
					:loading="loading"
					solo
					@change="changeTeam"
				>
					<template slot="selection" slot-scope="data">
						{{ data.item.name }}
					</template>
					<template slot="item" slot-scope="data">
						{{ data.item.name }}
					</template>
				</v-select>
			</v-col>

			<snackbar-text :text="error.message" bg-color="red" text-color="white" v-model="error.show" />
			<snackbar-text :text="success.message" bg-color="green" text-color="white" v-model="success.show" :button="false" />
		</v-row>
	</v-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import apiClient from "@/services/apiClient";
import SnackbarText from "@/components/snackbar-text";

export default {
	name: "dashboardHome",
	components: {SnackbarText},
	data: () => ({
		error: {
			show: false,
			message: "Team wijzigen mislukt."
		},
		success: {
			show: false,
			message: "Active team aangepast!"
		},
		loading: false,
	}),
	computed: {
		...mapGetters({
			user: "user/user"
		}),

	},
	methods: {
		...mapActions({
			refreshUser: 'user/refreshUser'
		}),
		changeTeam(newTeam){
			let params = {
				team_id: newTeam.id
			}

			apiClient.put("client/set_team", params).then(response => {
				this.success.show = true;
				this.loading = false;

				this.refreshUser();
			}).catch(error => {
				this.error.show = true;
				this.loading = false;
			});
		}
	}
};
</script>
